import React from "react";
import { UserIcon } from "../../../assets/SitePage/icons";

export const navbarLocations = [
  {
    id: "peniche-bar-do-bruno",
    href: "/beachcam/bar-do-bruno",
    label: "Bar do Bruno - Baleal",
  },
  {
    id: "cascais-carcavelos-tunnel",
    href: "/beachcam/carcavelos-tunnel",
    label: "Tunnel - Carcavelos",
  },
  {
    id: "costa-da-caprica-tarquinio",
    href: "/beachcam/caparica-tarquinio",
    label: "Tarquínio - Caparica",
  },
  {
    id: "costa-da-caprica-nova-praia",
    href: "/beachcam/nova-praia", 
    label: "Nova Praia - Caparica",
  },
  {
    id: "costa-da-caprica-fonte-de-telha",
    href: "/beachcam/caparica-fonte-da-telha",
    label: "Swara - Fonte da Telha",
  },
  {
    id: "aljezur-arrifana",
    href: "/beachcam/aljezur-arrifana",
    label: "Arrifana - Aljezur",
  },
];

export const navbarPools = [
  {
    id: "citywave-vilnius-lt",
    href: "https://www.citywave.lt/",
    label: "Citywave Vilnius",
  },
  {
    id: "rif010-rotterdam-nl",
    href: "https://rif010.surfeye.video/",
    label: "RiF010",
  },
  {
    id: "skudin-surf-ad-nyc-usa",
    href: "https://skudinsurfad.surfeye.video/",
    label: "Skudin Surf AD",
  },
  {
    id: "surf-centre-curitiba-br",
    href: "https://surfcenter.net/",
    label: "Surf Centre",
  },
  {
    id: "surf-langenfeld-de",
    href: "https://www.surf-langenfeld.de/",
    label: "Surf Langenfeld",
  },
  {
    id: "wakeparadise-milan-it",
    href: "https://wakeparadise.surfeye.video/",
    label: "Wakeparadise",
  },
  {
    id: "wellenwerk-berlin-de",
    href: "https://wellenwerk.surfeye.video/",
    label: "Wellenwerk",
  }
];

export const navbarRoutes = [
  // {
  //   id: "how-it-works",
  //   href: "/?section=howitworks",
  //   label: "How it works",
  // },
  // {
  //   id: "highlight",
  //   href: "/?section=highlight",
  //   label: "Highlights",
  // },
  {
    id: "locations",
    label: "Locations",
    children: navbarLocations,
  },
  // {
  //   id: "price",
  //   href: "/?section=price",
  //   label: "Pricing",
  // },
  {
    id: "profile",
    label: <UserIcon />,
    children: [
      {
        id: "login",
        href: "/?section=login",
        label: "Login",
      },
      {
        id: "register",
        href: "/?section=register",
        label: "Register",
      },
    ],
  },
];
